import store from '../../store'

export default [
    {
        value: "Project",
        text: store.getters.translate("projects"),
        children: [
            {
                value: "scheduler",
                text: store.getters.translate("scheduler"),
            },
            {
                value: "created",
                text: store.getters.translate("created"),
            },
            {
                value: "updated",
                text: store.getters.translate("updated"),
            },
            {
                value: "deleted",
                text: store.getters.translate("deleted"),
            },
        ],
    },
    {
        value: "Company",
        text: store.getters.translate("companies"),
        children: [
            {
                value: "scheduler",
                text: store.getters.translate("scheduler"),
            },
            {
                value: "created",
                text: store.getters.translate("created"),
            },
            {
                value: "updated",
                text: store.getters.translate("updated"),
            },
            {
                value: "deleted",
                text: store.getters.translate("deleted"),
            },
        ],
    },
    {
        value: "Contact",
        text: store.getters.translate("contacts"),
        children: [
            {
                value: "created",
                text: store.getters.translate("created"),
            },
            {
                value: "updated",
                text: store.getters.translate("updated"),
            },
            {
                value: "deleted",
                text: store.getters.translate("deleted"),
            },
            {
                value: "scheduler",
                text: store.getters.translate("scheduler"),
            },
            {
                value: "contact_has_logged_in",
                text: store.getters.translate("contact_has_logged_in"),
            },
        ],
    },
    {
        value: "Employee",
        text: store.getters.translate("employees"),
        relation: "employees",
        children: [
            {
                value: "created",
                text: store.getters.translate("created"),
            },
            {
                value: "updated",
                text: store.getters.translate("updated"),
            },
            {
                value: "deleted",
                text: store.getters.translate("deleted"),
            },
            {
                value: "scheduler",
                text: store.getters.translate("scheduler"),
            },
            {
                value: "employee_has_logged_in",
                text: store.getters.translate("employee_has_logged_in"),
            },

        ],
    },
]