<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-container>
      <v-row>
        <v-col>
          <base-tab-panel
              :module_name="page.name"
              :model="page.model"
              :record="record"
              @change="save()"
              @archive="archive()"
              :loading="loading">
            <template v-slot:main_fields>
              <v-text-field
                  :label="$store.getters.translate('name')"
                  v-model="record.name"/>
              <v-select
                  :label="$store.getters.translate('model_type')"
                  v-model="record.model_type"
                  :items="$lodash.sortBy(models, 'text')"/>
              <v-select
                  :label="$store.getters.translate('linked_model')"
                  v-model="record.linked_model"
                  :items="$lodash.sortBy(models, 'text')"
                  clearable/>
              <v-select
                  :label="$store.getters.translate('event')"
                  v-model="record.event"
                  :items="getEvents"/>
              <v-checkbox
                  inset
                  color="success"
                  v-model="record.active"
                  :label="$store.getters.translate('active')"/>
            </template>
            <template v-slot:module_fields>
              <v-textarea
                  :label="$store.getters.translate('description')"
                  v-model="record.description"
                  :disabled="loading"/>
            </template>
          </base-tab-panel>
        </v-col>
      </v-row>
      <!-- TRIGGERS -->
      <v-row>
        <v-col>
          <v-row>
            <v-col>
              <v-toolbar dark color="primary" class="elevation-1" dense flat>
                {{ $store.getters.translate("triggers") }}
                <v-spacer/>
                <v-btn text small @click="createTrigger()">
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </v-toolbar>
            </v-col>
          </v-row>
          <v-btn v-if="record.triggers.length == 0" @click="createTrigger()" block text color="primary" class="ma-10">
            {{ $store.getters.translate("create_trigger") }}
          </v-btn>
          <v-row>
            <v-col>
              <v-expansion-panels>
                <v-expansion-panel v-for="(trigger, index) in record.triggers" :key="index">
                  <v-expansion-panel-header>
                    <v-toolbar class="no-left-padding" dense flat>
                      <v-icon left class="mr-2">{{ getTriggerIcon(trigger.name) }}</v-icon>
                      <v-toolbar-title class="ma-0 pa-0">
                        {{ $store.getters.translate(trigger.name) }}:
                        <v-chip label class="ml-5">
                          {{ trigger.to }}
                        </v-chip>
                      </v-toolbar-title>
                    </v-toolbar>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-container>
                      <v-card flat class="mt-9" :disabled="loading">
                        <v-toolbar dense flat>
                          <v-select
                              :label="$store.getters.translate('event')"
                              @change="saveTrigger(trigger)"
                              :items="getTriggers"
                              v-model="trigger.type"/>
                          <v-spacer/>
                          <slot name="extra_buttons"/>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  @click="deleteTrigger(trigger)"
                                  v-bind="attrs"
                                  v-on="on"
                                  text
                                  small
                                  class="mr-2"
                                  color="secondary">
                                <v-icon color="error">mdi-trash-can</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $store.getters.translate("delete") }}</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  @click="saveTrigger(trigger)"
                                  v-bind="attrs"
                                  v-on="on"
                                  text
                                  small
                                  color="primary">
                                <v-icon>mdi-content-save</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $store.getters.translate("save") }}</span>
                          </v-tooltip>
                        </v-toolbar>
                        <v-card-text>
                          <v-row>
                            <v-col>
                              <v-select
                                  :label="$store.getters.translate('model')"
                                  v-model="trigger.model_type"
                                  :items="modelOptions"
                                  item-value="value"
                                  item-text="text"
                                  @change="getFields(trigger.model_type, trigger)"/>
                            </v-col>
                          </v-row>
                          <v-row v-if="trigger.type != 'added_tag' && trigger.type != 'has_tag'">
                            <v-col>
                              <v-select
                                  :label="$store.getters.translate('field')"
                                  v-model="trigger.subject"
                                  :items="fields"
                                  item-value="value"
                                  item-text="text"
                                  @focus="getFields(trigger.model_type, trigger.type)"/>
                            </v-col>
                          </v-row>
                          <v-row v-if="trigger.type != 'added_tag' && trigger.type != 'has_tag'">
                            <v-col>
                              <!--CHECKBOXES/BOOLEAN VALUES-->
                              <template v-if="getField(trigger.subject, trigger.type) && getField(trigger.subject, trigger.type).field_type == 'boolean'">
                                <v-checkbox v-model="trigger.to" :value="trigger.to"/>
                              </template>
                              <!--DATES-->
                              <template v-else-if="getField(trigger.subject, trigger.type) && getField(trigger.subject, trigger.type).field_type == 'date'">
                                <v-text-field :label="$store.getters.translate('amount_of_days')" v-model="trigger.to"/>
                              </template>
                              <!--DROPDOWNS-->
                              <template v-else-if="getField(trigger.subject, trigger.type) &&
                                    (getField(trigger.subject, trigger.type).field_type === 'select_single' ||
                                    getField(trigger.subject, trigger.type).field_type === 'select_multiple')">
                                <v-select
                                    :label="$store.getters.translate('field')"
                                    v-model="trigger.to"
                                    :items="getItems(trigger.subject, trigger.type)"
                                    item-value="value"
                                    item-text="text"/>
                              </template>
                              <!--TEXT FIELDS-->
                              <template v-else>
                                <v-text-field
                                    :label="$store.getters.translate('value')"
                                    v-model="trigger.to"/>
                              </template>
                              <template v-if="trigger.type == 'before_date' || trigger.type == 'after_date'">
                                <v-text-field
                                    :label="$store.getters.translate('amount_of_repeats')"
                                    v-model="trigger.amount_of_repeats"/>
                              </template>
                            </v-col>
                          </v-row>
                          <v-row v-if="trigger.type == 'added_tag' || trigger.type == 'removed_tag' || trigger.type == 'has_tag'">
                            <v-col>
                              <base-tags v-model="trigger.to" :available_tags="record.available_tags" :modal_name="modelRelation" :single="true"/>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-toolbar dark color="primary" class="elevation-1" dense flat>
                {{ $store.getters.translate("actions") }}
                <v-spacer/>
                <v-btn text small @click="createAction()">
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </v-toolbar>
            </v-col>
          </v-row>
          <v-btn v-if="record.actions.length == 0" @click="createAction()" block text color="primary" class="ma-10">
            {{ $store.getters.translate("create_action") }}
          </v-btn>
          <v-row>
            <v-col>
              <v-expansion-panels>
                <v-expansion-panel v-for="action in record.actions" :key="action.id">
                  <v-expansion-panel-header>
                    <v-toolbar class="no-left-padding" dense flat>
                      <v-icon left class="mr-2">{{ getActionIcon(action.name) }}</v-icon>
                      <v-toolbar-title class="ma-0 pa-0">
                        {{ $store.getters.translate(action.name) }}:
                        <v-chip v-html="determineSubject(action)" label class="ml-5 pt-4"/>
                      </v-toolbar-title>
                    </v-toolbar>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-container>
                      <v-card flat :disabled="loading">
                        <v-toolbar dense flat>
                          <v-select
                              color="success"
                              v-model="action.type"
                              :items="$lodash.sortBy(action_types, 'text')"/>
                          <v-spacer/>
                          <slot name="extra_buttons"/>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  text
                                  small
                                  @click="deleteAction(action)"
                                  class="mr-2"
                                  color="secondary">
                                <v-icon color="error">mdi-trash-can</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $store.getters.translate("delete") }}</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  @click="saveAction(action)"
                                  v-bind="attrs"
                                  v-on="on"
                                  text
                                  small
                                  color="primary">
                                <v-icon>mdi-content-save</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $store.getters.translate("save") }}</span>
                          </v-tooltip>
                        </v-toolbar>
                        <v-card-text class="pa-9">
                          <base-link
                              v-if="action.type === 'notify_users'"
                              @change="addModels"
                              @click="active_action = action"
                              :module_name="'users'"
                              :label="$store.getters.translate('choose_users')"
                              ref="base_link_users"
                              :excluded_ids="$lodash.map(active_action.models, 'id')"/>
                          <base-link
                              v-if="action.type === 'notify_roles'"
                              @change="addModels"
                              @click="active_action = action"
                              :module_name="'roles'"
                              :label="$store.getters.translate('choose_roles')"
                              ref="base_link_roles"
                              :excluded_ids="$lodash.map(active_action.models, 'id')"/>
                          <v-combobox
                              v-if="action.models.length > 0 && (action.type === 'notify_roles' || action.type === 'notify_users')"
                              class="mt-5"
                              clearable
                              v-model="action.models"
                              chips
                              deletable-chips
                              small-chips
                              multiple
                              item-value="id"
                              item-text="name"/>
                          <v-checkbox
                              v-if="action.type === 'notify_roles' || action.type === 'notify_users' || action.type === 'notify_current_user'"
                              :label="$store.getters.translate('send_email')"
                              v-model="action.parameters.send_email"/>
                          <v-text-field
                              v-if="action.type !== 'generate_document' && action.type !== 'notify_current_user' &&
                              action.type !== 'attach_tag' && action.type !== 'remove_tag' &&
                              action.type !== 'notify_users' && action.type !== 'notify_roles' && action.type !== 'notify_model'"
                              :label="$store.getters.translate('title')"
                              v-model="action.title"
                              counter/>
                          <edit-message
                              v-if="action.type != 'generate_document' && action.type != 'attach_tag' && action.type != 'remove_tag'"
                              v-model="action.message"
                              :model_type="record.model_type"
                              @change="saveAction(action)"/>
                          <template v-if="action.type == 'create_note' || action.type == 'attach_tag' || action.type == 'remove_tag' || action.type == 'notify_model'">
                            <span v-if="action.type == 'remove_tag'">{{ $store.getters.translate("remove_from") }}</span>
                            <span v-if="action.type == 'notify_model'">{{ $store.getters.translate("send_to") }}</span>
                            <span v-else>{{ $store.getters.translate("attach_to") }}</span>
                            <v-treeview
                                color="primary"
                                selectable
                                selection-type="independent"
                                v-model="action.parameters.attach_to"
                                :items="relatedModels"
                                :key="key_1"
                                open-all/>
                            <v-treeview
                                v-if="record.linked_model"
                                color="primary"
                                selectable
                                selection-type="independent"
                                v-model="action.parameters.linked_model_attach_to"
                                :items="relatedModelsLinked"
                                :key="'linked_model' + key_2"
                                open-all/>
                            <v-checkbox
                                :label="$store.getters.translate('only_latest_record')"
                                v-model="action.parameters.only_latest_record"/>
                          </template>
                          <v-checkbox
                              v-if="action.type == 'create_note'"
                              :label="$store.getters.translate('new_note')"
                              v-model="action.parameters.new_note"/>
                          <base-tags v-if="action.type == 'remove_tag' || action.type == 'attach_tag'" v-model="action.message" :available_tags="record.available_tags" :modal_name="modelRelation" :single="true"/>
                          <template v-if="action.type == 'create_note'">
                            <v-checkbox
                                :label="$store.getters.translate('generate_document')"
                                v-model="action.parameters.generate_document"/>
                          </template>
                          <template v-if="action.type == 'create_note' && action.parameters.generate_document">
                            <v-select
                                :label="$store.getters.translate('template')"
                                v-model="action.parameters.template_id"
                                :items="$lodash.sortBy(templates, 'name')"
                                item-value="id"
                                item-text="name"/>
                            <v-text-field
                                :label="$store.getters.translate('document_name')"
                                v-model="action.parameters.document_name"
                                counter/>
                          </template>
                          <template v-if="action.type == 'create_note'">
                            <base-row
                                v-for="file in action.files"
                                :key="file.id"
                                :value="file.name"
                                @delete="deleteFile(file)"
                                :label="$store.getters.translate('file')"
                                class="mt-3"
                                :link="true"
                                :hide_copy="true"
                                :deletable="true"/>
                            <v-row>
                              <v-col>
                                <v-file-input
                                    class="mt-3"
                                    :label="$store.getters.translate('add_files')"
                                    show-size
                                    v-model="action.new_files"
                                    multiple
                                    @change="saveFiles(action)"/>
                              </v-col>
                            </v-row>
                          </template>
                          <base-tags v-if="action.type == 'create_note'" v-model="action.parameters.tags" :available_tags="record.available_tags" modal_name="notes" :single="false"/>
                        </v-card-text>
                      </v-card>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import models from "./models";
import BaseTabPanel from "../../components/commonComponents/BaseTabPanel";
import EditMessage from "./fields/Edit";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import BaseLink from "../../components/commonComponents/BaseLink";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    BaseLink,
    BaseTabPanel,
    BaseBreadcrumb,
    EditMessage,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("workflows"),
        name: "workflows",
        model: 'workflow'
      },
      record: {},
      loading: false,
      active_action: {},
      active_trigger: {},
      fields: [],
      default_trigger: {
        workflow_id: null,
        model: "",
        type: "created",
        from: "",
        to: "",
        operator: "=",
        parameters: {
          operator: "=",
          compared_value: "",
          compared_type: "",
        },
        amount_of_repeats: 1,
      },
      default_action: {
        workflow_id: null,
        type: "notify_users",
        models: [],
        message: "",
        parameters: {
          generate_document: false,
          new_note: false,
          template_id: null,
          document_name: null,
          attach_to: [],
          linked_model_attach_to: [],
          tree: this.relatedModels,
          send_email: false,
          tags: [],
          only_latest_record: false,
        },
      },
      trigger_types: [
        {
          value: "added_tag",
          text: this.$store.getters.translate("added_tag"),
          icon: "mdi-tag-plus-outline",
        },
        {
          value: "field_changed",
          text: this.$store.getters.translate("field_changed"),
          icon: "mdi-form-textbox",
        },
        {
          value: "scheduler",
          text: this.$store.getters.translate("scheduler"),
          icon: "mdi-calendar",
        },
      ],
      countries: [],
      nationalities: [],
      action_types: [
        {
          value: "notify_current_user",
          text: this.$store.getters.translate("notify_current_user"),
          icon: "mdi-message-processing-outline",
        },
        {
          value: "notify_users",
          text: this.$store.getters.translate("notify_users"),
          icon: "mdi-android-messages",
        },
        {
          value: "notify_roles",
          text: this.$store.getters.translate("notify_roles"),
        },
        {
          value: "notify_model",
          text: this.$store.getters.translate("notify_model"),
          icon: "mdi-account-arrow-right",
        },
        {
          value: "attach_tag",
          text: this.$store.getters.translate("attach_tag"),
          icon: "mdi-tag-plus-outline",
        },
        {
          value: "remove_tag",
          text: this.$store.getters.translate("remove_tag"),
          icon: "mdi-tag-remove-outline",
        },
        {
          value: "create_note",
          text: this.$store.getters.translate("create_note"),
          icon: "mdi-note-plus",
        },
      ],
      tags: [],
      templates: [],
      key_1: 0,
      key_2: 0,
    };
  },
  created() {
    this.load();
    this.getCountryNationality();
  },
  methods: {
    async load() {
      this.loading = true;
      this.record = await helpFunctions.loadModel(this.$toasted, this.page.name, this.$route.params.id);
      this.key_1 = this.key_1 + 1;
      this.key_2 = this.key_2 + 1;
      this.loading = false;
    },
    async save() {
      this.loading = true;
      if(await helpFunctions.saveModel(this.$toasted, this.page.name, this.record)) {
        this.load();
      }
      this.loading = false;
    },
    async archive() {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toasted, this.page.name, this.record.id)) {
            await this.$router.push("/" + this.page.name);
          }
          this.loading = false;
        }
      });
    },
    createTrigger() {
      this.loading = true;
      this.record.triggers.push(this.$lodash.clone(this.default_trigger));
      this.$http
          .post(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id + "/triggers", this.$lodash.clone(this.default_trigger))
          .then(() => {
            this.loading = false;
            this.$toasted.success(this.$store.getters.translate("new_trigger_created"));
            this.load();
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    saveTrigger(trigger) {
      this.loading = true;
      this.$http
          .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id + "/triggers/" + trigger.id, trigger)
          .then(() => {
            this.loading = false;
            this.$toasted.success(this.$store.getters.translate("successfully_saved"));
            this.load();
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    createAction() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id + "/actions", this.$lodash.clone(this.default_action))
          .then(() => {
            this.loading = false;
            this.load();
            this.$toasted.success(this.$store.getters.translate("new_action_created"));
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    saveAction(action) {
      this.loading = true;
      this.$http
          .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id + "/actions/" + action.id, action)
          .then(() => {
            this.loading = false;
            this.$toasted.success(this.$store.getters.translate("successfully_saved"));
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    deleteTrigger(trigger) {
      if (!trigger) {
        return this.load();
      }
      this.loading = true;
      this.$http
          .delete(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id + "/triggers/" + trigger.id)
          .then(() => {
            this.loading = false;
            this.load();
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    deleteAction(action) {
      if (!action) {
        return this.load();
      }
      this.loading = true;
      this.$http
          .delete(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id + "/actions/" + action.id)
          .then(() => {
            this.loading = false;
            this.load();
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    insertContent(item) {
      if (item.name) {
        let model_type = item.model_type.toLowerCase() + ".";
        let source = item.source ? item.source.toLowerCase() + "." : "";
        let name = item.name.toLowerCase();
        this.$refs.rte_main.$refs.text_editor.editor.insertContent("${" + model_type + source + name + "}");
      }
    },
    async getFields(model_type, trigger_type) {
      this.loading = true;
      let date_param = "";
      if (trigger_type === "before_date" || trigger_type === "after_date") {
        date_param = "&field_type=date";
      }
      this.$http
          .get(this.$store.getters.appUrl + "v2/fields?model_type=" + model_type + date_param)
          .then((response) => {
            this.loading = false;
            let fields = [];
            response.data.forEach((field) => {
              if (field.name === "id" || field.name === "tags") {
                //IGNORE TAGS CHANGES FOR "FIELD CHANGED" TRIGGER
              } else {
                fields.push({
                  text: this.$store.getters.translate(field.name),
                  value: field.name,
                  available_options: field.available_options,
                  field_type: field.field_type,
                });
              }
            });
            this.fields = this.$lodash.sortBy(fields, "text");
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    addModels(objects) {
      objects.forEach((object) => {
        let new_object = {
          id: object.id,
          name: object.name,
        };
        this.active_action.models.push(new_object);
      });
    },
    getField(name) {
      return this.fields.find((field) => field.value === name);
    },
    getItems(name) {
      if (name === "country_of_birth_id") {
        return this.countries;
      } else if (name === "nationality_id") {
        return this.nationalities;
      } else {
        let available_options = this.fields.find(
            (field) => field.value === name
        ).available_options;
        let items = [];
        if (available_options) {
          available_options.forEach((option) => {
            items.push({
              text: this.$store.getters.translate(option),
              value: option,
            });
          });
        }
        return items;
      }
    },
    getCountryNationality() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/countries")
          .then((response) => {
            this.countries = [];
            response.data.country.forEach((option) => {
              this.countries.push({
                value: option.country_id,
                text: option.country_name,
              });
            });
            this.nationalities = [];
            response.data.nationality.forEach((option) => {
              this.nationalities.push({
                value: option.nationality_id,
                text: option.nationality_name,
              });
            });
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    getTags() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/tags?model=" + this.record.model_type)
          .then((response) => {
            this.loading = false;
            this.tags = response.data;
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    deleteFile(file) {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then((res) => {
            if (res) {
              this.loading = true;
              this.$http
                  .delete(this.$store.getters.appUrl + "v2/files/" + file.id)
                  .then(() => {
                    this.loading = false;
                    this.load(this.record.id);
                    this.$toasted.success(this.$store.getters.translate("success"));
                  }).catch((error) => {
                    if (this.$store.getters.isLoggedIn) {
                      this.$toasted.error(error);
                    }
                    this.loading = false;
                  });
            }
          });
    },
    saveFiles(action) {
      this.loading = true;
      const formData = new FormData();
      action.new_files.forEach((file) => {
        formData.append("attachment[]", file);
      });
      formData.append("action_id", action.id);
      this.$http
          .post(this.$store.getters.appUrl + "v2/actions/uploadfiles", formData, { headers: { "Content-Type": "multipart/form-data" }})
          .then(() => {
            this.loading = false;
            this.$toasted.success(this.$store.getters.translate("successfully_saved"));
            this.show = false;
            this.load(this.record.id);
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    download(file) {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/files/" + file.id, {
            responseType: "blob",
          })
          .then((response) => {
            this.loading = false;
            const blob = new Blob([response.data], { type: file.content_type });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = file.name;
            link.click();
            URL.revokeObjectURL(link.href);
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    getTemplates() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/templates?model=" + this.record.model_type)
          .then((response) => {
            this.loading = false;
            this.templates = response.data;
            this.templates.forEach((template) => {
              template.header = null;
            });
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    modelRelationInput(val) {
      return this.models.find((model) => model.value == val).relation;
    },
    getActionIcon(name) {
      let action_type = this.action_types.find(
          (action) => action.value == name
      );
      if (action_type) {
        return action_type.icon;
      }
      return null;
    },
    getTriggerIcon(name) {
      let trigger_type = this.trigger_types.find(
          (trigger) => trigger.value == name
      );
      if (trigger_type) {
        return trigger_type.icon;
      }
      return null;
    },
    determineSubject(action) {
      if (action.type == "notify_current_user") {
        return "<p>" + action.message + "</p>";
      }
      if (action.type == "notify_users") {
        return "<p>" + action.message + "</p>";
      }
      if (action.type == "notify_users") {
        return "<p>" + action.message + "</p>";
      }
      if (action.type == "notify_roles") {
        return "<p>" + action.message + "</p>";
      }
      if (action.type == "notify_roles") {
        return "<p>" + action.message + "</p>";
      }
      if (action.type == "notify_model") {
        return "<p>" + action.message + "</p>";
      }
      if (action.type == "attach_tag" || action.type == "remove_tag") {
        return "<p>" + action.message + "</p>";
      }
      if (action.type == "create_note") {
        return "<p>" + action.title + "</p>";
      }

      return "<p></p>";
    },
  },
  computed: {
    currentRecordName() {
      return this.record.name;
    },
    modelOptions() {
      if (this.record.linked_model) {
        return [
          {
            value: this.record.model_type,
            text: this.$store.getters.translate(this.record.model_type),
          },
          {
            value: this.record.linked_model,
            text: this.$store.getters.translate(this.record.linked_model),
          },
        ];
      }
      return [
        {
          value: this.record.model_type,
          text: this.$store.getters.translate(this.record.model_type),
        },
      ];
    },
    allTemplates() {
      return this.$lodash.sortBy(this.templates, "name");
    },
    panel: {
      set(value) {
        let payload = {
          module_name: this.page.name,
          value: value,
        };
        this.$store.dispatch("setPanel", payload);
      },
      get() {
        return this.$store.getters.getPanel(this.page.name);
      },
    },
    models() {
      return models;
    },
    getEvents() {
      return this.record.model_type ? this.$lodash.sortBy(this.models.find((model) => model.value == this.record.model_type).children, "text") : [];
    },
    getTriggers() {
      if (this.record.event == "scheduler") {
        return [
          {
            value: "before_date",
            text: this.$store.getters.translate("before_date"),
          },
          {
            value: "after_date",
            text: this.$store.getters.translate("after_date"),
          },
          {
            value: "field_changes",
            text: this.$store.getters.translate("field_changes"),
          },
          {
            value: "added_tag",
            text: this.$store.getters.translate("added_tag"),
          },
        ];
      } else {
        return [
          {
            value: "field_changes",
            text: this.$store.getters.translate("field_changes"),
          },
          {
            value: "added_tag",
            text: this.$store.getters.translate("added_tag"),
          },
        ];
      }
    },
    model() {
      return this.record.model_type;
    },
    modelRelation() {
      return this.models.find((model) => model.value == this.model).relation;
    },
    relatedModels() {
      return [];
    },
    relatedModelsLinked() {
      return [];
    },
  },
  watch: {
    model: {
      handler() {
        this.getTags();
        this.getTemplates();
        this.key = this.key + 1;
      },
    },
    active_action: {
      handler() {
        this.saveAction(this.active_action);
      },
    },
  },
};
</script>
